import './App.css';

import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import packageJson from "../package.json"
const Div = styled('div')(({ theme }) => ({
  ...theme.typography.button,
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(1),
}));



function App() {
  return (

    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: '100vh' }}
    >

      <Grid item xs={3}>
        <Box
          sx={{
            width: 400,
            height: 400,
            borderRadius: "5%",
            backgroundColor: 'primary.dark',
            '&:hover': {
              backgroundColor: 'primary.main',
              opacity: [0.9, 0.8, 0.7],
            }
          }}
        >

          {/* <Typography variant="h3" paragraph>
        Nazwa: {name}, wersja: {version}
      </Typography> */}
        </Box>
        <Div alignItems="center"
          justifyContent="center"
        >{"Nazwa: "}{packageJson.name}{`  wersja ${packageJson.version} `}</Div>
      </Grid>

    </Grid>


  );
}

export default App;
